<template>
  <v-container>
    <!-- {{ tags }} -->
    <v-row no-gutters>
      <v-col class="d-flex justify-center align-center">
        <h2 style="color: grey">Rastreo de Activos</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-center align-center">
        <v-img src="../assets/activos/11.png"  max-width="700" v-show="position == 11"></v-img>
        <v-img src="../assets/activos/22.png"  max-width="700" v-show="position == 22"></v-img>
        <v-img src="../assets/activos/33.png"  max-width="700" v-show="position == 33"></v-img>
        <v-img src="../assets/activos/12.png"  max-width="700" v-show="position == 12"></v-img>
        <v-img src="../assets/activos/13.png"  max-width="700" v-show="position == 13"></v-img>
        <v-img src="../assets/activos/23.png"  max-width="700" v-show="position == 23"></v-img>


        <!-- <v-img src="../assets/assetsGemExpo/1.png"  max-width="450" v-show="position == 1"></v-img>
        <v-img src="../assets/assetsGemExpo/2.png"  max-width="450" v-show="position == 2"></v-img>
        <v-img src="../assets/assetsGemExpo/11.png"  max-width="450" v-show="position == 11"></v-img>
        <v-img src="../assets/assetsGemExpo/22.png"  max-width="450" v-show="position == 22"></v-img>
        <v-img src="../assets/assetsGemExpo/12.png"  max-width="450" v-show="position == 12"></v-img> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="tags"
          :hide-default-footer="true"
          class="elevation-5"
          orderBy
        >
          <template v-slot:[`item.timestamp`]="{ item }">
            {{ new Date(item.timestamp * 1000).toLocaleDateString() }} - {{ new Date(item.timestamp * 1000).toLocaleTimeString() }}
          </template>

          <template v-slot:[`item.alarm`]="{ item }">
            <v-chip v-if="item.alarm == false" color="green" text-color="white">Bien</v-chip>
            <v-chip v-if="item.alarm == true" color="red" text-color="white">ALARMA</v-chip>
          </template>
        
        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {db} from '../firebase'
import {ref, onValue} from "firebase/database";

export default {
  name: 'Mesh',
  data() {
    return {
      tags: [],
      //position: 11,
      headers: [
        {text: 'Tag Id', align: 'start', value: 'tagId',},
        {text: 'Ancla', align: 'start', value: 'anchor',},
        {text: 'Posición', align: 'start', value: 'position',},
        {text: 'Hora', align: 'start', value: 'timestamp',},
        {text: 'Alarma', align: 'start', value: 'alarm',},
      ],
    }
  },

  computed: {
    position () {
      if (this.tags[0].anchor == 1 && this.tags[1].anchor == 1){return 11}
      else if (this.tags[0].anchor == 4041888 && this.tags[1].anchor == 4041888){return 22}
      else if (this.tags[0].anchor == 4041890 && this.tags[1].anchor == 4041890){return 33}
      else if (this.tags[0].anchor == 1 && this.tags[1].anchor == 4041888){return 12}
      else if (this.tags[0].anchor == 1 && this.tags[1].anchor == 4041890){return 13}
      else if (this.tags[0].anchor == 4041888 && this.tags[1].anchor == 4041890){return 23}
      else if (this.tags[0].anchor == 4041888 && this.tags[1].anchor == 1){return 12}
      else if (this.tags[0].anchor == 4041890 && this.tags[1].anchor == 1){return 13}
      else if (this.tags[0].anchor == 4041890 && this.tags[1].anchor == 4041888){return 23}
    } 
  },

  methods: {
    getTags () {
    const tagsRef = ref(db, 'meshTags')
    onValue(tagsRef, snapshot => {
      //console.log(snapshot.val())
      this.tags = Object.values(snapshot.val())
    })
  }
  },

  created() {
    this.getTags()
  },


}
</script>